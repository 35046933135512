/* Layout Geral */
.kanban-no-scroll {
    overflow: hidden;
}

.kanban-container {
    padding: 20px;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}

.kanban-filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
}

.kanban-filters > div {
    display: flex;
    flex-direction: column;
}

.kanban-filters label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

/* Estilo do board */
.react-kanban-board {
    display: flex;
    gap: 20px;
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    max-height: 100dvh;
    box-sizing: border-box;
}

/* Estilo da coluna */
.react-kanban-column {
    background-color: #e9ecef;
    border-radius: 8px;
    width: 300px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: calc(100vh - 260px);
    box-sizing: border-box;
}

/* Estilo do cabeçalho da coluna */
.react-kanban-column-header {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
    background-color: #dee2e6;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

/* Estilo dos cards */
.react-kanban-card {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.react-kanban-card:hover {
    box-shadow: inset 0 0 0 2px rgba(0, 123, 255, 0.8);
    position: relative;
}

.react-kanban-card-details p {
    margin: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Garantir o texto limitado dentro do card */
.react-kanban-card-details span {
    display: inline-block;
    background-color: #ddd;
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-kanban-card-details strong {
    color: #000;
}

@media (max-width: 768px) {c
    .kanban-filters {
        flex-direction: column;
    }

    .react-kanban-column {
        width: 100%;
    }

    .react-kanban-card {
        width: 100%;
    }
}
